:root {
    --hint-size: 16px;
    --hint-inner-ratio: 0.9;
    --hint-inner-used-ratio: 0.4;
    --border-radius: 0.05;
}

.hint-container{
    display: flex;
    justify-content: center;
    gap: var(--space-small);
    align-items: center;
    flex-flow: wrap;
}

.hint-indicator{
    height: var(--hint-size);
    width: var(--hint-size);
    display: flex;
    align-items: center;
    justify-content: center;

    .used {
        height: calc(var(--hint-size)*var(--hint-inner-used-ratio));
        width: calc(var(--hint-size)*var(--hint-inner-used-ratio));
        background-color: var(--empty-tile-color);
        border-radius: 50%
    };

    .unused {
        height: calc(var(--hint-size)*var(--hint-inner-ratio));
        width: calc(var(--hint-size)*var(--hint-inner-ratio));
        background-color: var(--snake-color);
        border-radius: 50%
    };
}