:root {
    --path-letter-color: black;
}


.word-container-path{
    .coloured-tile {
        background-color: var(--empty-tile-color);
    }
}

.tile-path-empty{
    .tile-letter{
        font-weight: normal;
        color: var(--path-letter-color);
    }
}

@keyframes bounceUp {
    0% {
        translate: 0px 0px;
    }
    50% {
        translate: 0px -16px;
    }
    100% {
        translate: 0px 0px;
    }
}

@media only screen and (max-width: 600px) {
    .path-large{
        display: none;
    }
    .path-medium {
        display: none;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
    .path-large {
        display: none;
    }
    .path-small {
        display: none;
    }
}

@media only screen and (min-width: 1000px) {
    .path-small {
        display: none;
    }
    .path-medium {
        display: none;
    }
}    
