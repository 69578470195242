:root{
    --grid-size-xlarge: 60px;
    --grid-size-large: 50px;
    --grid-size-medium: 42px;
    --grid-size-small: 36px;
    --grid-size-xsmall: 30px;

    --snake-offset-xlarge: 10px;
    --snake-offset-large: 8px;
    --snake-offset-medium: 7px;
    --snake-offset-small: 6px;
    --snake-offset-xsmall: 5px;

    --inner-offset-xlarge: 4px;
    --inner-offset-large: 3px;
    --inner-offset-medium: 3px;
    --inner-offset-small: 2px;
    --inner-offset-xsmall: 2px;

    --border-radius: 0.05;
    --grid-font-size: 0.36;
}

.grid-container{
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 360px) {
    .grid-container-large {
        --grid-size: var(--grid-size-xsmall);
        --snake-offset: var(--snake-offset-xsmall);
        --inner-offset: var(--inner-offset-xsmall);
    }

    .grid-container-medium {
        --grid-size: var(--grid-size-xsmall);
        --snake-offset: var(--snake-offset-xsmall);
        --inner-offset: var(--inner-offset-xsmall);
    }

    .grid-container-small {
        --grid-size: var(--grid-size-xsmall);
        --snake-offset: var(--snake-offset-xsmall);
        --inner-offset: var(--inner-offset-xsmall);
    }
}

@media only screen and (min-width: 360px) and (max-width: 400px) {
    .grid-container-large {
        --grid-size: var(--grid-size-small);
        --snake-offset: var(--snake-offset-small);
        --inner-offset: var(--inner-offset-small);
    }
    .grid-container-medium{
        --grid-size: var(--grid-size-xsmall);
        --snake-offset: var(--snake-offset-xsmall);
        --inner-offset: var(--inner-offset-xsmall);
    }
    .grid-container-small {
        --grid-size: var(--grid-size-xsmall);
        --snake-offset: var(--snake-offset-xsmall);
        --inner-offset: var(--inner-offset-xsmall);
    }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
    .grid-container-large {
        --grid-size: var(--grid-size-medium);
        --snake-offset: var(--snake-offset-medium);
        --inner-offset: var(--inner-offset-medium);
    }
    .grid-container-medium{
        --grid-size: var(--grid-size-small);
        --snake-offset: var(--snake-offset-small);
        --inner-offset: var(--inner-offset-small);
    }
    .grid-container-small {
        --grid-size: var(--grid-size-small);
        --snake-offset: var(--snake-offset-small);
        --inner-offset: var(--inner-offset-small);
    }
}

@media only screen and (min-width: 600px) {
    .grid-container-large {
        --grid-size: var(--grid-size-xlarge);
        --snake-offset: var(--snake-offset-xlarge);
        --inner-offset: var(--inner-offset-xlarge);
        
    }
    .grid-container-medium{
        --grid-size: var(--grid-size-large);
        --snake-offset: var(--snake-offset-large);
        --inner-offset: var(--inner-offset-large);
    }
    .grid-container-small {
        --grid-size: var(--grid-size-small);
        --snake-offset: var(--snake-offset-small);
        --inner-offset: var(--inner-offset-small);
    }
}

.tile {
    height: var(--grid-size);
    width: var(--grid-size);
    background-color: transparent;
    border: none;
    outline: none;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tile-color-1{
    .coloured-tile {
        background-color: var(--tile-color-1);
    }
    .inner-corner {
        background-color: var(--tile-color-1);
    }
}

.tile-color-2{
    .coloured-tile {
        background-color: var(--tile-color-2);
    }
    .inner-corner {
        background-color: var(--tile-color-2);
    }
}

.tile-color-3{
    .coloured-tile {
        background-color: var(--tile-color-3);
    }
    .inner-corner {
        background-color: var(--tile-color-3);
    }
}

.word-container{
    display: flex;
    justify-content: center;
}
  
.snake-head{
    pointer-events: none;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--snake-color);
    position: absolute;
    /* Border calculation */
    --round-border: calc(0.5*var(--grid-size) - var(--snake-offset));
}

.snake-preview{
    background-color: var(--snake-hover-color);
}

.hint{
    height: calc(var(--grid-size) - 2*var(--snake-offset));
    width: calc(var(--grid-size) - 2*var(--snake-offset));
    border-radius: var(--round-border);
    background-color: var(--hint-color);
}

.hint-wrong{
    background-color: var(--hint-color-wrong);
}

.end{
    height: calc(var(--grid-size) - 2*var(--snake-offset));
    width: calc(var(--grid-size) - 2*var(--snake-offset));
    margin: var(--snake-offset);
    border-radius: var(--round-border);
}

.south-end{
    height: calc(var(--grid-size) - var(--snake-offset));
    width: calc(var(--grid-size) - 2*var(--snake-offset));
    margin: var(--snake-offset) var(--snake-offset) 0 var(--snake-offset);
    border-radius: var(--round-border) var(--round-border) 0 0;
}

.north-end{
    height: calc(var(--grid-size) - var(--snake-offset));
    width: calc(var(--grid-size) - 2*var(--snake-offset));
    margin: 0 var(--snake-offset) var(--snake-offset) var(--snake-offset);
    border-radius: 0% 0% var(--round-border) var(--round-border);
}

.west-end {
    height: calc(var(--grid-size) - (2 * var(--snake-offset)));
    width: calc(var(--grid-size) - var(--snake-offset));
    margin: var(--snake-offset) var(--snake-offset) var(--snake-offset) 0;
    border-radius: 0% var(--round-border) var(--round-border) 0%;
}

.east-end {
    height: calc(var(--grid-size) - (2 * var(--snake-offset)));
    width: calc(var(--grid-size) - var(--snake-offset));
    margin: var(--snake-offset) 0 var(--snake-offset) var(--snake-offset);
    border-radius: var(--round-border) 0% 0% var(--round-border);
}

.vertical-through{
    height: var(--grid-size);
    width: calc(var(--grid-size) - 2*var(--snake-offset));
    margin: 0 var(--snake-offset) 0 var(--snake-offset);
}

.horizontal-through{
    height: calc(var(--grid-size) - 2*var(--snake-offset));
    width: var(--grid-size);
    margin: var(--snake-offset) 0 var(--snake-offset) 0;
}

.bend{
    height: calc(var(--grid-size) - var(--snake-offset));
    width: calc(var(--grid-size) - var(--snake-offset));
}

.south-east {
    margin: var(--snake-offset) 0 0 var(--snake-offset);
    border-radius: var(--round-border) 0 0 0;
    .corner{
        top: calc((0.5 * var(--grid-size)) - var(--snake-offset));
        left: calc((0.5 * var(--grid-size)) - var(--snake-offset));
    }
    .inner-corner{
        border-radius: 0 0 calc(var(--grid-size)*var(--border-radius)) 0;
    }
}

.south-west {
    margin: var(--snake-offset) var(--snake-offset) 0 0 ;
    border-radius: 0 var(--round-border) 0 0;
    .corner{
        top: calc((0.5 * var(--grid-size)) - var(--snake-offset));
        left: calc((-0.5 * var(--grid-size)) + var(--snake-offset));
    }
    .inner-corner{
        position: relative;
        left: var(--inner-offset);
        border-radius: 0 0 0 calc(var(--grid-size)*var(--border-radius));
    }
}

.north-east {
    margin:  0 0 var(--snake-offset) var(--snake-offset);
    border-radius: 0 0 0 var(--round-border);
    .corner{
        top: calc((-0.5 * var(--grid-size)) + var(--snake-offset));
        left: calc((0.5 * var(--grid-size)) - var(--snake-offset));
    }
    .inner-corner{
        position: relative;
        top: var(--inner-offset);
        border-radius: 0 calc(var(--grid-size)*var(--border-radius)) 0 0;
    }
}

.north-west {
    margin:  0 var(--snake-offset) var(--snake-offset) 0 ;
    border-radius: 0 0 var(--round-border) 0;
    .corner{
        top: calc((-0.5 * var(--grid-size)) + var(--snake-offset));
        left: calc((-0.5 * var(--grid-size)) + var(--snake-offset));
    }
    .inner-corner{
        position: relative;
        left: var(--inner-offset);
        top: var(--inner-offset);
        border-radius: calc(var(--grid-size)*var(--border-radius)) 0 0 0;
    }
}

.corner{
    height: var(--snake-offset);
    width: var(--snake-offset);
    position: relative;
    background-color: var(--inner-corner-color);
}

.inner-corner {
    --calculated-offset: calc(var(--snake-offset) - var(--inner-offset));
    height: var(--calculated-offset);
    width: var(--calculated-offset);
}

.tile-letter{
    z-index: 2;
    position: absolute;
    font-size: calc(var(--grid-size)*var(--grid-font-size));
    font-weight: bold;
    color: var(--snake-letter-color);
}

.inner-square{
    position: absolute;
    height: calc(var(--grid-size) - 2 * var(--inner-offset));
    width: calc(var(--grid-size) - 2 * var(--inner-offset));
    border-radius: calc(var(--grid-size)*var(--border-radius));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: inherit;
    box-sizing: border-box;
}

.inner-corner, .inner-square{
    background-color: var(--empty-tile-color);
}

.hintTapIndicator{
    background-color: white;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    z-index: 2;
    animation-name: pulse;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.hintTapIndicator.hidden {
    background-color: transparent;
}

@keyframes pulse {
    0% {
        transform: scale(0.3,0.3)
    }

    100% {
        transform: scale(0.6,0.6)
    }
}